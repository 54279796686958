import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { actionTypes } from '../../reducer';
import { GetCarMakes } from '../../Services';
import { useStateValue } from '../../StateProvider';
import ButtonSelect from '../buttonSelect/ButtonSelect';
import CenteredContainer from '../centeredContainer/CenteredContainer';
import Spinner from '../spinner/Spinner';
import StepContainer from '../stepContainer/StepContainer';
import commonCarMakes from '../../commonMakes.json';
import './MakeSelector.css';
import { SetProgress } from '../../Utils';

const MakeSelector = ({ question, inputName, redirectTo, progress }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ { wizardData, progressData }, dispatch ] = useStateValue();
	const [ buttonsMakes, setButtonsMakes ] = useState([]);
	const [ otherMakes, setOtherMakes ] = useState([]);
	const history = useHistory();

	useEffect(() => {
		window.gtag('event', 'page_view', {
			page_title : question,
			page_path  : window.location.href
		});

		window.scrollTo({
			top      : 0,
			left     : 0,
			behavior : 'smooth'
		});
	}, []);

	useEffect(
		() => {
			// handle resize
			if (window.formSizeNotify) {
				window.formSizeNotify();
			}
		},
		[ isLoading ]
	);

	useEffect(
		() => {
			if (progress) {
				dispatch({
					type         : actionTypes.SET_PROGRESS,
					progressData : progress
				});
			}
		},
		[ progress ]
	);

	useEffect(() => {
		let tempData = {
			...wizardData,
			[inputName] : null
		};

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : tempData
		});

		getMakes();
	}, []);

	useEffect(
		() => {
			if (wizardData && wizardData[inputName] && !isLoading) {
				if (wizardData[inputName] === 'Other') {
					history.push('/currentlyInsured');
					return;
				}

				history.push(redirectTo);
			}
		},
		[ wizardData ]
	);

	const getMakes = async () => {
		if (!wizardData.modelYear) {
			//history.push('/');
			history.goBack();
		}

		let data = {
			Year : wizardData.modelYear
		};

		const response = await GetCarMakes(data);

		if (response.Success) {
			let responseMakes = JSON.parse(response.Data);
			responseMakes.push({
				name : 'Other'
			});

			setButtonsMakes(responseMakes);

			/* temporarily disabled */

			// let commonMakeNames = new Array();

			// if (commonCarMakes && commonCarMakes.length > 0) {
			// 	for (let i = 0; i < commonCarMakes.length; i++) {
			// 		commonMakeNames.push(commonCarMakes[i].name);
			// 	}
			// }

			// let tempCommonMakes = responseMakes.filter(
			// 	(x) => commonMakeNames.indexOf(x.name) > -1
			// );

			// if (tempCommonMakes && tempCommonMakes.length > 0) {
			// 	let tempOtherMakes = responseMakes.filter(
			// 		(x) => tempCommonMakes.indexOf(x) === -1
			// 	);

			// 	if (tempOtherMakes && tempOtherMakes.length > 0) {
			// 		let tempArray = new Array();

			// 		for (let i = 0; i < tempOtherMakes.length; i++) {
			// 			tempArray.push(tempOtherMakes[i].name);
			// 		}

			// 		setOtherMakes(tempArray);
			// 	}

			// 	let tempButtonsMakes = new Array();

			// 	for (let i = 0; i < tempCommonMakes.length; i++) {
			// 		const matchingCarMake = commonCarMakes.find(
			// 			(x) => x.name === tempCommonMakes[i].name
			// 		);

			// 		tempButtonsMakes.push(matchingCarMake);
			// 	}

			// 	setButtonsMakes(tempButtonsMakes);
			// } else {
			// 	let tempOtherMakes = new Array();

			// 	for (let i = 0; i < responseMakes.length; i++) {
			// 		tempOtherMakes.push(responseMakes[i].name);
			// 	}

			// 	setOtherMakes(tempOtherMakes);
			// }

			/* end of temporarily disabled */
		} else {
			let tempData = {
				...wizardData,
				[inputName] : 'Other'
			};

			dispatch({
				type       : actionTypes.SET_WIZARD_DATA,
				wizardData : tempData
			});
			history.push('/currentlyInsured');
		}

		setIsLoading(false);
	};

	const handleSelection = (make) => {
		let tempData = {
			...wizardData,
			[inputName] : make
		};

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : tempData
		});
	};

	const handleDropdownChange = (e) => {
		handleSelection(e.target.value);
	};

	return (
		<Fragment>
			{!isLoading ? (
				<Fragment>
					<StepContainer>
						<h3>{question}</h3>
						{buttonsMakes &&
						buttonsMakes.length > 0 && (
							<Fragment>
								<div className="makes-buttons-container">
									{buttonsMakes.map((make) => (
										<ButtonSelect
											key={make.name}
											onClick={() =>
												handleSelection(make.name)}
										>
											{/* <img
												src={`/img/logos/${make.logo}`}
												alt={`${make.name} Logo`}
											/> */}
											<span>{make.name}</span>
										</ButtonSelect>
									))}
								</div>
							</Fragment>
						)}

						{otherMakes &&
						otherMakes.length > 0 && (
							<Fragment>
								<div className="input-container">
									<select
										name="other-makes"
										className="form-control form-control-lg mt-3"
										onChange={handleDropdownChange}
									>
										<option value="" key="other-makes">
											Select Other Makes
										</option>

										{otherMakes.map((make) => (
											<option key={make} value={make}>
												{make}
											</option>
										))}
									</select>
								</div>
							</Fragment>
						)}
					</StepContainer>
				</Fragment>
			) : (
				<Fragment>
					<Spinner />
				</Fragment>
			)}
		</Fragment>
	);
};

export default MakeSelector;
