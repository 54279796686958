import { Fragment, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Complete from '../complete/Complete';
import CreditScoreSelector from '../creditScore/CreditScoreSelector';
import GenderSelector from '../genderSelector/GenderSelector';
import HowCanWeHelpSelector from '../howCanWeHelp/HowCanWeHelpSelector';
import InsuranceProviderSelector from '../insuranceProviderSelector/InsuranceProviderSelector';
import MakeSelector from '../makeSelector/MakeSelector';
import ModelSelector from '../modelSelector/ModelSelector';
import TextCaptureQuestion from '../textCaptureQuestion/TextCaptureQuestion';
import ThankYou from '../thankYou/ThankYou';
import Tickets from '../tickets/Tickets';
import YearSelector from '../yearSelector/YearSelector';
import YesNoQuestion from '../yesNoQuestion/YesNoQuestion';
import AreYouInsured from '../areYouInsured/AreYouInsured';
import ZipCode from '../zipCode/ZipCode';
import ProgressBar from '../progressBar/ProgressBar';
import queryString from 'query-string';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import ZipCodeB from '../zipCodeB/ZipCodeB';
import CollectInformation from '../collectInformation/CollectInformation';

const Wizard = () => {
	const [
		{ flowData, wizardData, campaignData },
		dispatch
	] = useStateValue();
	const locationQuery = useLocation();

	useEffect(() => {
		// parse out all tracking from the url and store
		let params = queryString.parse(locationQuery.search);
		let utmParams = {};

		if (params.gclid) {
			utmParams.ClickId = params.gclid;
		} else {
			utmParams.ClickId = '';
		}

		if (params.tblci) {
			utmParams.ClickId = params.tblci;
		}

		if (params.utm_source) {
			utmParams.utm_source = params.utm_source;
		} else {
			utmParams.utm_source = '';
		}

		if (params.utm_medium) {
			utmParams.utm_medium = params.utm_medium;
		} else {
			utmParams.utm_medium = '';
		}

		if (params.utm_content) {
			utmParams.utm_content = params.utm_content;
		} else {
			utmParams.utm_content = '';
		}

		if (params.utm_campaign) {
			utmParams.utm_campaign = params.utm_campaign;
		} else {
			utmParams.utm_campaign = '';
		}

		dispatch({
			type       : actionTypes.SET_WIZARD_DATA,
			wizardData : {
				...wizardData,
				...utmParams
			}
		});

		if (utmParams.clickId && utmParams.clickId !== '') {
			dispatch({
				type     : actionTypes.SET_FLOW_DATA,
				flowData : {
					...flowData,
					ClickId : utmParams.ClickId
				}
			});
		}
	}, []);

	return (
		<Fragment>
			{/* <ProgressBar
				location={campaignData.state}
				amount={28}
				progress={0}
			/> */}
			<Switch>
				<Route path="/redirect">
					<CollectInformation />
				</Route>
				<Route path="/thank-you">
					<ThankYou />
				</Route>
				<Route path="/quote">
					<Complete progress={90} />
				</Route>
				<Route path="/age">
					<YesNoQuestion
						question="Are you 25 or older?"
						inputName="age"
						redirectTo="/redirect"
						progress={78}
					/>
				</Route>
				<Route path="/accident">
					<YesNoQuestion
						question="Have you had an at-fault accident in the past 3 years?"
						inputName="accident"
						redirectTo="/age"
						progress={71.5}
					/>
				</Route>
				<Route path="/dui">
					<YesNoQuestion
						question="Have you had a DUI conviction in the past 3 years?"
						inputName="duiConviction"
						redirectTo="/accident"
						progress={65}
					/>
				</Route>
				<Route path="/tickets">
					<Tickets
						question="How many tickets have you had in the past 3 years?"
						inputName="tickets"
						redirectTo="dui"
						progress={58.5}
					/>
				</Route>
				<Route path="/license">
					<YesNoQuestion
						question="Do you have an active license?"
						inputName="activeLicense"
						redirectTo="/tickets"
						progress={52}
					/>
				</Route>
				<Route path="/creditScore">
					<CreditScoreSelector
						question="How would you rate your credit score?"
						inputName="creditScore"
						redirectTo="/license"
						progress={45.5}
					/>
				</Route>
				<Route path="/gender">
					<GenderSelector
						question="What is your gender?"
						inputName="gender"
						redirectTo="/creditScore"
						progress={39}
					/>
				</Route>
				<Route path="/insuranceProvider">
					<InsuranceProviderSelector
						question="Who is your current insurance provider?"
						inputName="insuranceProvider"
						redirectTo="/gender"
						progress={32.5}
					/>
				</Route>
				<Route path="/currentlyInsured">
					<AreYouInsured
						question="Are you currently insured?"
						inputName="currentlyInsured"
						redirectTo="/insuranceProvider"
						progress={26}
					/>
				</Route>
				<Route path="/model">
					<ModelSelector
						question="What's the model of your vehicle?"
						inputName="model"
						redirectTo="/currentlyInsured"
						progress={19.5}
					/>
				</Route>
				<Route path="/make">
					<MakeSelector
						question="What's the make of your vehicle?"
						inputName="make"
						redirectTo="/model"
						progress={13}
					/>
				</Route>
				<Route path="/year">
					<YearSelector
						question="What's the year of your vehicle?"
						inputName="modelYear"
						redirectTo="/make"
						progress={6.5}
					/>
				</Route>
				{/* <Route path="/info">
					<HowCanWeHelpSelector
						question="How can we help you?"
						inputName="howCanWeHelp"
						redirectTo="/year"
					/>
				</Route> */}
				<Route path="/b" exact>
					<ZipCodeB
						inputName="zipCode"
						redirectTo="/year"
						progress={0}
					/>
				</Route>
				<Route path="/:zipCode?" exact>
					<ZipCode
						question="Enter Your ZIP Code:"
						inputName="zipCode"
						redirectTo="/year"
						progress={0}
					/>
				</Route>
			</Switch>
		</Fragment>
	);
};

export default Wizard;
