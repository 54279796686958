import { useEffect } from 'react';
import { useStateValue } from '../../StateProvider';
import Spinner from '../spinner/Spinner';
import StepContainer from '../stepContainer/StepContainer';
import './CollectInformation.css';

const CollectInformation = () => {
	const [
		{ wizardData, flowData, progressData, campaignData, redirectUrl },
		dispatch
	] = useStateValue();

	useEffect(() => {
		// handle resize
		if (window.formSizeNotify) {
			window.formSizeNotify();
		}
		collectAllInformation();
	}, []);

	const collectAllInformation = () => {
		let tempData = {
			...wizardData,
			...flowData,
			...campaignData
		};

		if (
			!tempData.insuranceProvider ||
			tempData.insuranceProvider.trim() === ''
		) {
			tempData.insuranceProvider = 'Other';
		}

		if (!tempData.zipCode || tempData.zipCode.trim() === '') {
			tempData.zipCode = '00000';
		}

		//const response = await AddNewLead(tempData);

		// parse all object keys and add them as parameters to redirect url
		let paramsArray = new Array();

		for (const [ key, value ] of Object.entries(tempData)) {
			if (key !== 'url') {
				paramsArray.push(`${key}=${value}`);
			}
		}

		console.log(paramsArray);

		let paramsString = paramsArray.join('&');
		let finalRedirectUrl = `${redirectUrl}?${paramsString}`;

		console.log(paramsString, finalRedirectUrl);

		if (redirectUrl) {
			window.top.location.href = finalRedirectUrl;
		}
	};

	return (
		<StepContainer>
			<Spinner />
		</StepContainer>
	);
};

export default CollectInformation;
